<template>
  <div class="pa-2">
    <v-card class="mx-auto" max-width="800">
      <v-card-title>プライバシーポリシー</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="ma-4">はじめに</h2>
        <p class="ml-4">
          キワ・アート・アンド・デザイン株式会社は、お客様の個人情報保護を尊重するために、利用者の個人情報の適切な取り扱いに取り組んでまいります。
          以下に当社のサービス（以下「本サービス」とします）にて収集する個人情報・利用方法についての規定（プライバシーポリシー）を記載します。当サービスをご利用頂く全ての方（以下「利用者」といいます）は、当社のプライバシーポリシーに同意頂いているとみなします。ご不安な点やご質問がございましたら、
          <ExtarnalLink
              url="https://kaad.jp/"
              linkText="当社Webサイト"
              linkEventLabel="company link"
            />からお問い合わせください。
        </p>
        <h2 class="ma-4">第１条（個人情報の定義）</h2>
        <p class="ml-4">
          個人情報とは、本サービスを通じて利用者から取得する氏名、生年月日、メールアドレス、パスワード、会員IDのほか、ログ情報、Cookie及び匿名ID、位置情報、当社が定める入力フォームにユーザーが入力する情報、その他利用者個人を識別できる情報のことを指します。
          収集する個人情報は、利用者から本サービスにてご登録・ご提供いただく情報に限られます。なお、本サービスの準拠法は日本国内法とします。
        </p>
        <h2 class="ma-4">第２条（個人情報の利用目的）</h2>
        <p class="ml-4">
          当社は、利用者の個人情報を以下の目的で利用することができるものとします。
        </p>
        <ul class="ml-4">
          <li>本サービスおよびその他本サービスに関連する情報の提供</li>
          <li>利用者の本人確認</li>
          <li>
            本サービスの運営上必要な事項の通知（電子メールによるものを含むものとします）
          </li>
          <li>
            当社および第三者の商品等の広告または宣伝（電子メールによるものを含むものとします。）
          </li>
          <li>
            本サービスが利用者に有用だと思われる当社又は当社が提携する第三者のキャンペーン等のご案内を含むメールマガジンの送信
          </li>
          <li>
            個人を識別できない形式に加工した上での統計情報の作成および利用
          </li>
          <li>本サービスの新規開発に必要なデータの解析や分析</li>
          <li>
            利用者の行動、性別、アクセス履歴などを用いたターゲティング広告の配信
          </li>
          <li>契約や法律等に基づく権利の行使や義務の履行</li>
          <li>アフターサービス、各種問い合わせ対応</li>
          <li>その他上記に関連する業務の遂行</li>
        </ul>
        <h2 class="ma-4">第３条（個人情報の共有および第三者提供）</h2>
        <p class="ml-4">
          当社は、原則として、事前に利用者の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を利用または第三者に共有することはありません。ただし、以下に定める場合には、利用者の同意を得ずに第三者に提供できるものとし、利用者は予めこれに同意するものとします。
        </p>
        <ul class="ml-4">
          <li>法令により認められた場合</li>
          <li>利用者の同意があった場合</li>
          <li>
            利用者が投稿し、当社が第三者に再利用許諾した口コミ等のコンテンツに利用者の個人情報が含まれる場合
          </li>
          <li>
            個人を識別することができない状態で統計的なデータとして開示・提供する場合
          </li>
          <li>
            人の生命、身体または財産の保護のために必要があり、さらに利用者の同意を得るのが困難な場合
          </li>
          <li>
            裁判所、検察庁、警察、税務署、弁護士会またはこれらに準じた権限を持つ機関から、個人情報の開示を求められた場合
          </li>
          <li>
            本サービスの提供において当社が必要と判断した際、弁護士等、当社に対して秘密保持義務を負う者に対して開示する場合
          </li>
          <li>
            合併、営業譲渡その他の事由による事業の承継の際に、事業を承継する者に対して開示する場合
          </li>
        </ul>
        <h2 class="ma-4">第４条（匿名加工情報）</h2>
        <p class="ml-4">
          当社は、特定の個人を識別することができないように個人情報を加工し、当該個人情報を復元できないようにした情報（以下「匿名加工情報」といいます）を反復、継続的に作成し、当社の関連会社・提携先等の第三者に提供しています。当社が匿名加工情報を取り扱う際には、法令の定めに従い、匿名加工情報取扱事業者等の義務を遵守します。
        </p>
        <ol class="ml-4" type="a">
          <li>
            匿名加工情報に含まれる項目
            <p>利用者の個人属性情報（性別、年齢等）</p>
          </li>
          <li>
            第三者提供の方法
            <p>
              上記aの情報が含まれる電子データを暗号化し、セキュリティが確保された手段で提供します。
            </p>
          </li>
        </ol>
        <h2 class="ma-4">第５条（個人データの取り扱いの委託）</h2>
        <p class="ml-4">
          当社は、事業運営上、業務委託先に個人データの取り扱いを委託することがあります。この場合、当社は、個人データを適切に保護できる管理体制を敷き実行していることを条件として委託先を厳選したうえで、契約等において個人データの適正管理・機密保持などにより利用者の個人データの漏えい防止に必要な事項を取り決め、適切な管理を実施させます。
        </p>
        <h2 class="ma-4">第６条（個人データの安全管理）</h2>
        <p class="ml-4">
          当社は、個人データの漏洩または毀損の防止およびその他個人情報の安全管理が行われるよう、個人データの適切な監督を行います。当社は、業務遂行に必要な範囲内で、権限を与えられた者のみが個人データを取り扱います。個人データの取扱いを外部に委託する場合には、機密保持契約を締結のうえ委託先を監督します。
        </p>
        <h2 class="ma-4">第７条（本サービス以外での個人情報の管理）</h2>
        <p class="ml-4">
          本サービスを通じてアクセスできる第三者のサイトおよびサービス等、本サービスからのリンク先のウェブサイトで独自に収集される個人情報の利用については、当社は、関知いたしません。
        </p>
        <p class="ml-4">
          そのため、これらの企業またはサイトにおける、独立した規定や活動に対して、当社は一切の義務や責任を負いません。
          それぞれのサイトのプライバシーポリシーを確認してください。
        </p>
        <h2 class="ma-4">第８条（クッキー等）</h2>
        <p class="ml-4">
          本サービスでは、クッキー（Cookie）と呼ばれる技術を利用しています。
        </p>
        <p class="ml-4">
          クッキーとは、特定のテキスト情報を利用者の利用する通信機器に一時的にデータとして保持させ、接続の度にそのデータを基に利用者を識別させる仕組みをいいます。
          当社では、クッキーの利用を前提としたサービスを提供しているため、クッキーの利用を許可しない場合、当社の一部のサービスを受けられないことがあります。
        </p>
        <p class="ml-4">
          クッキーの利用を許可するかどうかは、利用者のブラウザで設定できます。必要に応じて設定を確認してください。
          当社は、利用者に対し、より有用な情報を提供するため、クッキーを含む以下の情報（以下「クッキー等データ」といいます）を取得しています。
        </p>
        <ol class="ml-4" type="1">
          <li>
            クッキーID（ウェブサイトによるクッキー送信の度に付される符号）
          </li>
          <li>
            利用者がコンピュータ（ブラウザ）により訪問したウェブサイトのページのURL
          </li>
          <li>
            利用者がコンピュータ（ブラウザ）により閲覧したウェブサイトのページの分類（セグメント）および分析結果等
          </li>
          <li>
            利用者がコンピュータ（ブラウザ）により訪問したウェブサイト、閲覧したウェブサイトのページその他の遷移および行動の履歴
          </li>
        </ol>
        <h3 class="ma-4">アクセス解析用のクッキーに関する説明</h3>
        <p class="ml-4">
          当社は、サイト利用状況を把握するために以下の第三者企業が提供するサービスを利用しており、第三者企業によりクッキー等データが取得、利用されることがあります。なお、取得したクッキー等データは第三者企業に提供されることがあります。第三者企業によるクッキー等データの取得、利用を中止したい場合には、お手数ですが、以下のオプトアウトページにアクセスし、手順に従い無効化してください。
          <ul>
          <li>
            Google Analytics
            <p>
              <ExtarnalLink
              url="https://tools.google.com/dlpage/gaoptout?hl=ja"
              linkText="https://tools.google.com/dlpage/gaoptout?hl=ja"
              linkEventLabel="google opt out"
              />
            </p>
          </li>
        </ul>
        </p>
        <h2 class="ma-4">第９条（位置情報）</h2>
        <p class="ml-4">
          当社は、デバイスから利用者の位置情報(緯度経度情報)を、利用者の同意がある場合に限り取得しています。位置情報は、利用者のニーズに適したサービスの提供、サービスの改善、またはマーケティングリサーチや広告配信の最適化のために行うものとし、その他の目的には一切利用いたしません。
        </p>
        <h2 class="ma-4">第１０条（保有個人データの開示、訂正、削除）</h2>
        <p class="ml-4">
          当社は、個人情報保護法その他の法令等に基づき、保有個人データの開示、訂正、追加、削除、利用停止、消去、第三者提供の停止、利用目的の通知の請求に対応いたします。
          請求が本人確認不可能な場合や、個人情報保護法の定める要件を満たさない場合、ご希望に添えない場合があります。
          なお、アクセスログなどの個人情報以外の情報については、原則として開示等はいたしません。
        </p>
        <h2 class="ma-4">第１１条（プライバシーポリシーの更新）</h2>
        <p class="ml-4">
          当社は、個人情報保護を図るため、法令等の変更や必要に応じて、本プライバシーポリシーを改定することがあります。その際は、最新のプライバシーポリシーを本サービスに掲載いたします。本ページを都度ご確認の上、本サービスのプライバシーポリシーをご理解いただくようお願いします。
        </p>
        <h2 class="ma-4">第１２条（プライバシーポリシーに関するお問合せ）</h2>
        <p class="ml-4">
          当社のプライバシーポリシーに関するお問合せ、個人情報の開示等は、以下までご連絡お願いします。
        </p>
        <div class="ml-4 mt-12">
          <p>
            住所：〒113-0021 東京都文京区本駒込6-15-18クリエーション六義園1・2階
          </p>
          <p>
            <ExtarnalLink
              url="https://kaad.jp/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b%ef%bd%9ccontact-us/"
              linkText="お問い合わせフォーム"
              linkEventLabel="company contact form"
              />
          </p>
          <p>キワ・アート・アンド・デザイン株式会社</p>
        </div>
        <p>2020年12月1日制定</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ExtarnalLink from "@/components/ExtarnalLink.vue";

export default {
  components: { ExtarnalLink },
  metaInfo: {
    title: "プライバシーポリシー",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "プライバシーポリシーを掲載しています。",
      },
    ],
  },
};
</script>